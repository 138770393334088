
import { defineComponent } from "vue";
import { featureIcons } from "@/layouts/workspace/menu-settings";
export default defineComponent({
  name: "Account",
  setup() {
    return {
      featureIcons,
      steps: [
        {
          label: "Titulaire d'un compte",
          icon: "account",
          color: "#9C27B0",
        },
        {
          label: "Etre membre d'une activité",
          icon: "activity",
          color: "#FF9800",
        },
        {
          label: "Enregistrer des produits",
          icon: "product",
          color: "#673AB7",
        },
        {
          label: "Gérer les stocks",
          icon: "stock",
          color: "#f57c00",
        },
        {
          label: "Effectuer des ventes",
          icon: "pos",
          color: "#9C27B0",
        },
      ],
    };
  },
});
